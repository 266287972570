/**
 * @Author: Anandp
 * @Date:   2024-11-21 14:38:58
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 22:03:03
 */
import axios from "axios";
import { Url } from "src/config";

const mediaUploadApis = {
  /**
   * Uploads media to a specific team on the dashboard.
   * 
   * @async
   * @function uploadMedia
   * @param {string} accessToken - The access token for authentication.
   * @param {string} dashboard - The dashboard identifier where the media will be uploaded.
   * @param {string|number} teamId - The ID of the team to associate the media with.
   * @param {File} media - The media file to be uploaded.
   * @returns {Promise<Object>} A promise resolving to the response data from the upload request.
   * 
   * @example
   * // Example usage:
   * const uploadedData = await uploadMedia("yourAccessTokenHere", "dashboardId", "teamId", mediaFile);
   * console.log("Uploaded Media:", uploadedData);
   * 
   * @throws Will log the error message if the request fails.
   */
  uploadMedia: async (accessToken, dashboard, teamId, media) => {
    try {
      const formData = new FormData();
      formData.append("media", media);
      const response = await axios.post(
        `${Url}/customer/uploadMedia`,
        formData,
        {
          headers: {
            "access-token": accessToken,
          },
          params: {
            teamId: teamId,
            dashboard: dashboard,
          },
        }
      );
      // Handle response
      return response.data; // Return data if needed
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * Retrieves media associated with a specific team on the dashboard.
   * 
   * @async
   * @function getMedia
   * @param {string} accessToken - The access token for authentication.
   * @param {string} dashboard - The dashboard identifier from which to fetch the media.
   * @param {string|number} teamId - The ID of the team whose media is to be fetched.
   * @returns {Promise<Object>} A promise resolving to the media data retrieved from the request.
   * 
   * @example
   * // Example usage:
   * const mediaData = await getMedia("yourAccessTokenHere", "dashboardId", "teamId");
   * console.log("Fetched Media:", mediaData);
   * 
   * @throws Will log the error message if the request fails.
   */
  getMedia: async (accessToken, dashboard, teamId) => {
    try {
      const response = await axios.get(`${Url}/customer/getMedia`, {
        headers: {
          "access-token": accessToken,
        },
        params: {
          teamId: teamId,
          dashboard: dashboard,
        },
      });
      // Handle response
      return response.data; // Return data if needed
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * Retrieves a specific file based on the provided file name and file path.
   * 
   * @async
   * @function getExactFile
   * @param {string} accessToken - The access token for authentication.
   * @param {Object} fileDetails - An object containing the file details.
   * @param {string} fileDetails.fileName - The name of the file to retrieve.
   * @param {string} fileDetails.filePath - The path where the file is stored.
   * @returns {Promise<Object>} A promise resolving to the file data retrieved from the request.
   * 
   * @example
   * // Example usage:
   * const fileData = await getExactFile("yourAccessTokenHere", { fileName: "example.jpg", filePath: "/files/example/" });
   * console.log("Fetched File:", fileData);
   * 
   * @throws Will log the error message if the request fails.
   */
  getExactFile: async (accessToken, { fileName, filePath }) => {
    try {
      const response = await axios.post(`${Url}/customer/getExactFile`, { fileName, filePath }, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * Uploads a PDF file to the server for a specific team and dashboard.
   * 
   * @async
   * @function uploadPdf
   * @param {string} accessToken - The access token for authentication.
   * @param {string} dashboard - The dashboard identifier where the PDF will be uploaded.
   * @param {string} teamId - The ID of the team for which the PDF is being uploaded.
   * @param {File} pdf - The PDF file to upload.
   * @returns {Promise<Object>} A promise resolving to the server's response data upon successful upload.
   * 
   * @example
   * // Example usage:
   * const pdfFile = document.getElementById("pdfInput").files[0];
   * const uploadResponse = await uploadPdf("yourAccessTokenHere", "dashboard1", "team123", pdfFile);
   * console.log("PDF Uploaded:", uploadResponse);
   * 
   * @throws Will log the error message if the request fails.
   */
  uploadPdf: async (accessToken, dashboard, teamId, base64Pdf, fileName, fieldName) => {
    try {
      const payload = {
        pdf: base64Pdf, // Sending as a Base64 string
        teamId: teamId,
        dashboard: dashboard,
        fileName: fileName,
        fieldname: fieldName,
      };
  
      const response = await axios.post(`${Url}/customer/uploadPdf`, payload, {
        headers: {
          "access-token": accessToken,
          "Content-Type": "application/json",
        },
      });
      // Handle response
      return response.data; // Return data if needed
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * Fetches a PDF file associated with a specific team and dashboard.
   * 
   * @async
   * @function getPdf
   * @param {string} accessToken - The access token for authentication.
   * @param {string} dashboard - The dashboard identifier where the PDF is stored.
   * @param {string} teamId - The ID of the team for which the PDF is being fetched.
   * @returns {Promise<Object>} A promise resolving to the server's response, containing the PDF data.
   * 
   * @example
   * // Example usage:
   * const pdfResponse = await getPdf("yourAccessTokenHere", "dashboard1", "team123");
   * console.log("Fetched PDF:", pdfResponse);
   * 
   * @throws Will log the error message if the request fails and rethrows the error.
   */
  getPdf: async (accessToken, dashboard, teamId) => {
    try {
      const response = await axios.get(`${Url}/customer/getPdf`, {
        headers: {
          "access-token": accessToken,
        },
        params: {
          teamId: teamId,
          dashboard: dashboard,
        }
      });

      // You can return any relevant data if needed
      return response;
    } catch (error) {
      console.log("Error fetching PDF:", error.message);
      // Handle error accordingly
      throw error; // Optionally rethrow the error for further handling
    }
  },
  /**
   * Uploads a PDF file for a specific user and dashboard.
   * 
   * @async
   * @function uploadCWCPdf
   * @param {string} accessToken - The access token for authentication.
   * @param {string} dashboard - The dashboard identifier where the PDF is being uploaded.
   * @param {string} userId - The ID of the user for whom the PDF is being uploaded.
   * @param {File} pdf - The PDF file to be uploaded.
   * @returns {Promise<Object>} A promise resolving to the server's response, containing the result of the upload.
   * 
   * @example
   * // Example usage:
   * const pdfResponse = await uploadCWCPdf("yourAccessTokenHere", "dashboard1", "user123", pdfFile);
   * console.log("PDF upload result:", pdfResponse);
   * 
   * @throws Will log the error message if the request fails.
   */
  uploadCWCPdf: async (accessToken, dashboard, userId, base64Pdf, fileName, fieldName) => {
    try {
      const payload = {
        pdf: base64Pdf,
        userId: userId,
        dashboard: dashboard,
        fileName: fileName,
        fieldname: fieldName,
      }
      const response = await axios.post(
        `${Url}/customer/uploadCWBPdf`,
        payload,
        {
          headers: {
            "access-token": accessToken,
            "Content-Type": "application/json",
          },
         
        }
      );
      // Handle response
      return response.data; // Return data if needed
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * Retrieves the CWBPdf for a specific user and dashboard.
   * 
   * @async
   * @function getCWBPdf
   * @param {string} accessToken - The access token for authentication.
   * @param {string} dashboard - The dashboard identifier from which the PDF is being fetched.
   * @param {string} userId - The ID of the user whose PDF is being fetched.
   * @returns {Promise<Object>} A promise resolving to the server's response, containing the requested PDF data.
   * 
   * @example
   * // Example usage:
   * const pdfData = await getCWBPdf("yourAccessTokenHere", "dashboard1", "user123");
   * console.log("PDF data received:", pdfData);
   * 
   * @throws Will log the error message if the request fails.
   */
  getCWBPdf: async (accessToken, dashboard, userId) => {
    try {
      const response = await axios.get(`${Url}/customer/getCWBPdf`, {
        headers: {
          "access-token": accessToken,
        },
        params: {
          userId: userId,
          dashboard: dashboard,
        },
      });
      // Convert the response blob to an array of individual blobs
      //   const pdfBlob = response.data;
      //   // Log the size and type of the blob
      return response;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * Retrieves all CWB PDFs for a specific user and dashboard.
   * 
   * @async
   * @function getAllCWBPdf
   * @param {string} accessToken - The access token for authentication.
   * @param {string} dashboard - The dashboard identifier from which the PDFs are being fetched.
   * @param {string} userId - The ID of the user whose PDFs are being fetched.
   * @returns {Promise<Object>} A promise resolving to the server's response, containing all requested CWB PDF data.
   * 
   * @example
   * // Example usage:
   * const allPdfs = await getAllCWBPdf("yourAccessTokenHere", "dashboard1", "user123");
   * console.log("All CWB PDFs received:", allPdfs);
   * 
   * @throws Will log the error message if the request fails.
   */
  getAllCWBPdf: async (accessToken, dashboard, userId) => {
    try {
      const response = await axios.get(`${Url}/customer/getAllCWBPdf`, {
        headers: {
          "access-token": accessToken,
        },
        params: {
          userId: userId,
          dashboard: dashboard,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * Downloads a selected PDF for a specific user.
   * 
   * @async
   * @function DownloadSelectedPdf
   * @param {string} accessToken - The access token for authentication.
   * @param {string} pdfId - The ID of the PDF to be downloaded.
   * @param {string} userId - The ID of the user for whom the PDF is being downloaded.
   * @returns {Promise<Object>} A promise resolving to the server's response, containing the selected PDF data.
   * 
   * @example
   * // Example usage:
   * const pdfData = await DownloadSelectedPdf("yourAccessTokenHere", "pdf123", "user123");
   * console.log("PDF Data:", pdfData);
   * 
   * @throws Will log the error message if the request fails.
   */
  DownloadSelectedPdf: async (accessToken, pdfId, userId) => {
    try {
      const response = await axios.get(`${Url}/customer/DownloadSelectedPdf`, {
        headers: {
          "access-token": accessToken,
        },
        params: {
          pdfid: pdfId,
          userid: userId
        },
      });
      return response;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * Uploads a profile image for a specific user.
   * 
   * @async
   * @function uploadProfileImage
   * @param {string} accessToken - The access token for authentication.
   * @param {string} dashboard - The dashboard identifier where the image is being uploaded.
   * @param {string} userid - The ID of the user whose profile image is being uploaded.
   * @param {FormData} media - The profile image data in the form of a `FormData` object.
   * @returns {Promise<Object>} A promise resolving to the server's response data.
   * 
   * @example
   * // Example usage:
   * const media = new FormData();
   * media.append("profileImage", file);
   * const response = await uploadProfileImage("yourAccessTokenHere", "dashboard123", "user123", media);
   * console.log("Upload Response:", response);
   * 
   * @throws Will log the error message if the request fails.
   */
  uploadProfileImage: async (accessToken, dashboard, userid, media) => {
    try {
      // const formData = new FormData();
      // formData.append("media", media);
      const response = await axios.post(
        `${Url}/customer/uploadProfilePicture`,
        media,
        {
          headers: {
            "access-token": accessToken,
          },
          params: {
            userid: userid,
            dashboard: dashboard,
          },
        }
      );
      // Handle response
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * Fetches the profile picture of a specific user and stores it in session storage.
   * 
   * @async
   * @function getProfilePicture
   * @param {string} accessToken - The access token for authentication.
   * @param {string} userid - The ID of the user whose profile picture is being fetched.
   * @returns {Promise<Object|null>} A promise resolving to the user's profile picture data, or `null` if not found.
   * 
   * @example
   * // Example usage:
   * const profilePicture = await getProfilePicture("yourAccessTokenHere", "user123");
   * console.log("Profile Picture Data:", profilePicture);
   * 
   * @throws Will log the error message if the request fails.
   * 
   * @remarks
   * - Stores the profile picture data in session storage under the key `profilePicture`.
   * - Dispatches `storage` and `profilePic` events to notify listeners of the update.
   */
  getProfilePicture: async (accessToken, userid) => {
    try {
      const response = await axios.get(`${Url}/customer/getProfilePicture`, {
        headers: {
          "access-token": accessToken,
        },
        params: {
          userid: userid,
        },
      });
      // Handle response
      const encrypted = JSON.stringify(response.data.mediaData ?? null)
      // sessionStorage.setItem("surveyPerms", JSON.stringify(response.data.surveyIds));
      sessionStorage.setItem("profilePicture", encrypted);
      window.dispatchEvent(new Event('storage'));
      window.dispatchEvent(new Event('profilePic'));
      return response.data.mediaData; // Return data if needed
    } catch (error) {
      console.log(error.message);
    }
  },
};

export default mediaUploadApis;

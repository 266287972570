import React from "react";
import Typography from "@mui/material/Typography";
import { createTypography } from "src/theme/base/customTypography";
import * as SldSurveyCard from "src/theme/base/SldSurveyCard";
import Stack from "@mui/material/Stack";


/**
 * Mojo survey tile component
 * @module MojoSurveyStartPage - tileComponent
 * @returns {JSX.Element} The rendered component.
 */
function MyMojoSurveyTile() {
  const cardComponents = [
    {
      image: "/assets/Survey-Graphics/Mojo-1.svg",
      text: "Evaluate your emotional wellness, life-balance, passion, and energy levels",
    },
    {
      image: "/assets/Survey-Graphics/Mojo-2.svg",
      text: "Understand the impact of well-being and overall functioning on work and personal life ",
    },
    {
      image: "/assets/Survey-Graphics/Mojo-3.svg",
      text: "Boost your own and your team’s wellness through personal insights",
    },
  ];

const typography = createTypography();
  return (
    <Stack
    spacing={5}
    justifyContent="center"
    direction={{lg:'row',md:'column', sm:'column', xs:'column'}}
    >
 {cardComponents.map((item) => (
      <SldSurveyCard.SurveyCardStyle
        key={item.text}
        sx={{ width: {xs:'300px',sm:'350px',lg:'30%'} }}
      >
        {item.image ? (
          <SldSurveyCard.SurveyCardMedia
      
          >
           <img
                src={item.image}  
                alt={item.text}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
          </SldSurveyCard.SurveyCardMedia>
        ) : (
          <SldSurveyCard.SurveyCardMedia style={{ height: 238 }} />
        )}

        <SldSurveyCard.SurveyCardContent
        >
                    <Typography sx={{ ...typography.cardContent, textAlign: "center" ,wordWrap: 'break-word', fontSize:{xl:'18px',lg:'15px', md:'14px',sm:'14px', xs:'13px'}}}>
            {item.text}
          </Typography>
        </SldSurveyCard.SurveyCardContent>
      </SldSurveyCard.SurveyCardStyle>
    ))}
  </Stack>
  );
}

export default MyMojoSurveyTile;

import React from "react";
import Typography from "@mui/material/Typography";
import { createTypography } from "src/theme/base/customTypography";
import * as SldSurveyCard from "src/theme/base/SldSurveyCard";
import Stack from "@mui/material/Stack";


/**
 * 360 surveyTile component
 * @module 360SurveyStartPage - 360SurveyTile 
 * @returns {JSX.Element} The rendered component
 */
function TeamEffectivenessSurveyTile() {
  const cardComponents = [
    {
      image: "/assets/Survey-Graphics/360-1.svg",
      text: "Evaluate where your team is on the journey to becoming a high performing team",
    },
    {
      image: "/assets/Survey-Graphics/360-2.svg",
      text: "Identify your team's position on key characteristics of effective teams ",
    },
    {
      image: "/assets/Survey-Graphics/360-3.svg",
      text: "Highlight areas that need essential team development efforts and initiatives",
    },
  ];

  const typography = createTypography();
  return (
    <Stack
    spacing={5}
    justifyContent="center"
    direction={{lg:'row',md:'column', sm:'column', xs:'column'}}
    >
 {cardComponents.map((item) => (
      <SldSurveyCard.SurveyCardStyle
        key={item.text}
        sx={{ width: {xs:'300px',sm:'350px',lg:'30%'} }}
        >
          {item.image ? (
            <SldSurveyCard.SurveyCardMedia
             
            >
              <img
                src={item.image}  
                alt={item.text}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </SldSurveyCard.SurveyCardMedia>
          ) : (
            <SldSurveyCard.SurveyCardMedia style={{ height: 238 }} />
          )}

          <SldSurveyCard.SurveyCardContent
           
          >
                                <Typography sx={{ ...typography.cardContent, textAlign: "center" ,wordWrap: 'break-word', fontSize:{xl:'18px',lg:'15px', md:'14px',sm:'14px', xs:'13px'}}}>

              {item.text}
            </Typography>
          </SldSurveyCard.SurveyCardContent>
        </SldSurveyCard.SurveyCardStyle>
      ))}
    </Stack>
  );
}

export default TeamEffectivenessSurveyTile;

/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2025-01-20 12:43:24
 */
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";
import { paths } from "src/paths";
import ExtensionIcon from "@mui/icons-material/Extension";
import { ReactComponent as SettingsIcon } from "src/icons/untitled-ui/settingsIcon.svg";
import { ReactComponent as MyMojoNav } from "../../icons/untitled-ui/MyMojoNav.svg";
import { ReactComponent as MyExeNav } from "../../icons/untitled-ui/MyExeNav.svg";
import { ReactComponent as MyLeaderNav } from "../../icons/untitled-ui/MyLeaderNav.svg";
import { ReactComponent as MypulseTrendsNav } from "../../icons/untitled-ui/MypulseTrendsNav.svg";
import { ReactComponent as IslNav } from "../../icons/untitled-ui/IslNav.svg"
import { ReactComponent as GoalsPulsesNav } from "../../icons/untitled-ui/GoalsPulsesNav.svg"
import { ReactComponent as CoachesCornerNav } from "../../icons/untitled-ui/CoachesCornerNav.svg"
import { ReactComponent as Convwithboss } from "../../icons/untitled-ui/Convwithboss.svg"
import { useUser } from "src/hooks/use-user";
import CryptoJS from 'crypto-js';
export const useSections = () => {
  const { t } = useTranslation();
  // const dashboardPerms = JSON.parse(sessionStorage.getItem("dashboardPerms"));
  // const surveyPerms = JSON.parse(sessionStorage.getItem("surveyPerms"));
  const user = useUser();
  const dashboardPermsEn = sessionStorage.getItem("dashboardPerms");
  const surveyPermsEn = sessionStorage.getItem("surveyPerms");
  const surveyPerms = surveyPermsEn && CryptoJS.AES.decrypt(surveyPermsEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8);
  const dashboardPerms = dashboardPermsEn && CryptoJS.AES.decrypt(dashboardPermsEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8);
  return useMemo(() => {
    const sections = [
      {
        subheader: "Menu",
        items: [],
      },
    ];
    // eslint-disable-next-line
    if (surveyPerms && (JSON.parse(surveyPerms).includes(7) || surveyPerms && JSON.parse(surveyPerms).includes(8))
    ) {
      sections[0].items.splice(1, 0, {
        title: "My Personality Profile",
        path: paths.dashboard.leadself.index,
        icon: (
          <SvgIcon fontSize="small">
            <ExtensionIcon />
          </SvgIcon>
        ),
      });
    }

    // Check if dashboardPerms contains the required value (3)
    // eslint-disable-next-line
    if (surveyPerms && (JSON.parse(surveyPerms).includes(9) || surveyPerms && JSON.parse(surveyPerms).includes(12))) {
      sections[0].items.splice(2, 0, {
        title: "My Mojo",
        path: paths.dashboard.mymojo.index,
        icon: (
          <SvgIcon fontSize="small">
            <MyMojoNav />
          </SvgIcon>
        ),
      });
    }

    if (surveyPerms && JSON.parse(surveyPerms).includes(10)) {
      sections[0].items.splice(3, 0, {
        title: "My Leadership",
        path: paths.dashboard.myLeadership.index,
        icon: (
          <SvgIcon fontSize="small">
            <MyLeaderNav />
          </SvgIcon>
        ),
      });
    }

    if (surveyPerms && JSON.parse(surveyPerms).includes(11)) {
      sections[0].items.splice(4, 0, {
        title: "My Executive Health",
        path: paths.dashboard.myExecutiveHealth.index,
        icon: (
          <SvgIcon fontSize="small">
            <MyExeNav />
          </SvgIcon>
        ),
      });
    }
    if (surveyPerms && JSON.parse(surveyPerms).includes(6) && JSON.parse(dashboardPerms).includes(13)) {
      sections[0].items.splice(5, 0, {
        title: "My Pulse Trends",
        path: paths.dashboard.myPulseTrends.index,
        icon: (
          <SvgIcon fontSize="small">
            <MypulseTrendsNav />
          </SvgIcon>
        ),
      });
    }
    if (Number(user.roleId) === 104) {
      sections[0].items.splice(6, 0, {
        title: "Lead Self Settings",
        path: paths.dashboard.leadselfSettings.index,
        icon: (
          <SvgIcon fontSize="small">
            <SettingsIcon />
          </SvgIcon>
        ),
      });
    }
    // eslint-disable-next-line
    if ((dashboardPerms && Number(user.tenetId) === 0 && JSON.parse(dashboardPerms).includes(12)) || user.tenetId !== 0 && user.roleId === 101 || user.roleId === 102) {
      sections[0].items.splice(7, 0, {
        title: user.roleId === 101 ? "Coaches Corner" : "Accredited Practitioner",
        path: user.tenetId !== 0 ? `/teams/${user.tenetId}/0` : paths.dashboard.coachesCorner.index,
        icon: (
          <SvgIcon fontSize="small">
            <CoachesCornerNav />
          </SvgIcon>
        ),
      });
    }
    // eslint-disable-next-line
    if (dashboardPerms && ((Number(user.tenetId) === 0 && JSON.parse(dashboardPerms)?.includes(8)) || (Number(user.tenetId) === 0 && JSON.parse(dashboardPerms).includes(9)))) {
      const filteredPerms = JSON.parse(dashboardPerms).filter(perm => [8, 9].includes(perm));
      const goalsAndPulsesItem = (Number(user.tenetId) === 0 && filteredPerms.includes(8)) ? {
        title: "My Goals & Pulses",
        path: paths.dashboard.islProgrammes.goalsAndPulses,
        icon: (
          <SvgIcon fontSize="small">
            <GoalsPulsesNav />
          </SvgIcon>
        ),
      } : {};

      const cwbItem = (Number(user.tenetId) === 0 && filteredPerms.includes(9)) ? {
        title: "Conversation With Boss",
        path: paths.dashboard.conversationWithBoss.index,
        icon: (
          <SvgIcon fontSize="small">
            <Convwithboss />
          </SvgIcon>
        ),
      } : {};

      sections[0].items.splice(7, 0, {
        title: "ISL Programmes",
        icon: (
          <SvgIcon fontSize="small">
            <IslNav />
          </SvgIcon>
        ),
        items: [goalsAndPulsesItem, cwbItem].filter(item => Object.keys(item).length !== 0),
      });
    }
    return sections;
    // // eslint-disable-next-line
    // if (dashboardPerms && ((Number(user.tenetId) === 0 && JSON.parse(dashboardPerms)?.includes(8)) || (Number(user.tenetId) === 0 && JSON.parse(dashboardPerms).includes(9)) || JSON.parse(dashboardPerms).includes(12))) {
    //   const filteredPerms = JSON.parse(dashboardPerms).filter(perm => [8, 9, 12].includes(perm));
    //   const goalsAndPulsesItem = (Number(user.tenetId) === 0 && filteredPerms.includes(8)) ? {
    //     title: "My Goals & Pulses",
    //     path: paths.dashboard.islProgrammes.goalsAndPulses,
    //     icon: (
    //       <SvgIcon fontSize="small">
    //         <GoalsPulsesNav />
    //       </SvgIcon>
    //     ),
    //   } : {};

    //   const cwbItem = (Number(user.tenetId) === 0 && filteredPerms.includes(9)) ? {
    //     title: "Conversation With Boss",
    //     path: paths.dashboard.conversationWithBoss.index,
    //     icon: (
    //       <SvgIcon fontSize="small">
    //         <Convwithboss />
    //       </SvgIcon>
    //     ),
    //   } : {};

    //   const coachItem = filteredPerms.includes(12) ? {
    //     title: "Coaches Corner",
    //     path: paths.dashboard.coachesCorner.index,
    //     icon: (
    //       <SvgIcon fontSize="small">
    //         <CoachesCornerNav />
    //       </SvgIcon>
    //     ),
    //   } : {};

    //   sections[0].items.splice(7, 0, {
    //     title: "ISL Programmes",
    //     icon: (
    //       <SvgIcon fontSize="small">
    //         <IslNav />
    //       </SvgIcon>
    //     ),
    //     items: [goalsAndPulsesItem, cwbItem, coachItem].filter(item => Object.keys(item).length !== 0),
    //   });
    // }
    // return sections;


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardPerms, t]);
};

/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 21:47:04
 */
import axios from "axios";
import { CmsUrl, Url } from "src/config";
// import { bearerToken } from "src/config";


const surveyAPIService = {
  /**
   * Function to check invite id
   * @param {object} inviteIdData
   * @param {string} accessToken
   * @returns
   */
  checkInviteId: async (inviteIdData, accessToken) => {
    try {
      const response = await axios.post(
        `${Url}/customer/checkInviteId`,
        inviteIdData,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  /**
   * API call to check survey section
   * @returns
   */
  getSurveySections: async (surveyData, accessToken,tenantId) => {
    try {
      let condition = '';
      if(tenantId!== undefined && tenantId !== null){
        condition = `?tenant_id=${tenantId}`
      }
      const response = await axios.post(
        `${Url}/customer/getSurveySections${condition}`,
        surveyData,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return error.response.data;
    }
  },
  /**
   * API call to get questions of a section
   * @param {object} surveyQuestionData
   * @returns
   */
  getSurveyQuestions: async (surveyQuestionData, accessToken,tenantId) => {
    try {
      let condition = '';
      if( (tenantId!==null && tenantId !== undefined)){
        condition = `?tenant_id=${tenantId}`
      }
      const response = await axios.post(
        `${Url}/customer/getSurveyQuestions${condition}`,
        surveyQuestionData,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },
  /**
   * API call to submit survey
   * @param {*} surveyResponse
   * @returns
   */
  submitSurveyResponse: async (surveyResponse, accessToken,tenantId) => {
    try {
      let condition = '';
      if( tenantId !== null && tenantId !== undefined){
        condition = `?tenant_id=${tenantId}`
      }
      const response = await axios.post(
        `${Url}/customer/submitSurveyAnswers${condition}`,
        surveyResponse,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },
  /**
   * Function to check current status of wiredup survey
   * @param {*} accessToken
   * @returns
   */
  checkSurveyStatus: async (accessToken) => {
    try {
      const response = await axios.get(`${Url}/customer/getwiredupstatus`, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  getColorCode : async (accessToken) => {
    try {
      const response = await axios.get(`${Url}/customer/getcolorcode`, {
        headers: {
          "access-token": accessToken,
        },
      })
      return response.data
    } catch (error) {
      
    }
  },
  getSurveyIntropageData: async (id, key) =>{
    try {
      const response = await axios.get(`${CmsUrl}/surveys-intros?filters%5Bsurvey_id%5D=${id}
      `,{headers: {
        'Authorization': `Bearer ${key}`,
        'Content-Type': 'application/json', // You can add other headers as needed
      },});
      return response.data
    } catch (error) {
      
    }
  },
  getCustomerUsers : async (accessToken,searchdata) => {
   
 
    try {
      let condition = '';
      if(searchdata && searchdata.length>2){
        condition = `?search=${searchdata}`
      }
      const response = await axios.get(`${Url}/customer/getUsers${condition}`,{
        headers: {
          "access-token": accessToken,
        },
      });
     return  response.data
    } catch (error) {
      return error
    }
  },
  sendSurveyInvitation : async (accessToken,users,data) =>{
    try {
     
      let teamIdCondition ='';
      if(data.teamid && data.teamid !== undefined){
        teamIdCondition=`&teamId=${data.teamid}`
        
      }
      const response = await axios.post(`${Url}/customer/inviteUsers?userId=${data.senderId}&surveyId=${data.surveyId}&senderName=${data.senderName}&currentTime=${data.currentTime}&timezone=${data.timeZone}&surveyEndDate=${data.surveyEndDate}${teamIdCondition}`,users,{headers: {
        'access-token':accessToken
      },})
     return  response.data
    
    } catch (error) {
      return error
    }
  },
  postSurveyFeedback: async (data, accessToken) => {
    try {
      const response = await axios.post(
        `${Url}/customer/submitfeedback`,
        data,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },
  getStrapiKey :async(accessToken)=>{
    try {
      let response =await axios.get(`${Url}/customer/getStrapiKey`,{
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data
    } catch (error) {
      console.log('--error in getting strapi  key--',error);
      return error
    }
  },
  getInvitedUsername :async(accessToken,body,tenantId)=>{
    try {
      console.log('---inside get invited username',body,accessToken,tenantId);
      
      let response =await axios.post(`${Url}/customer/invitedUserName?tenant_id=${tenantId}`,body,{
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data
    } catch (error) {
      console.log('--error in getting strapi  key--',error);
      return error
    }
  }
};

export default surveyAPIService;

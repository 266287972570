import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { createTypography } from "src/theme/base/customTypography";
import * as SldSurveyCard from "src/theme/base/SldSurveyCard";

/**
 * WirdeUp survey tiles component
 *@module wiredUpSurveyStartPage - wiredupSurveyTiles 
* @returns {JSX.Element} The rendered component
 */
function SurveyTile() {
  const cardComponents = [
    {
      image: "/assets/Survey-Graphics/wired-up-1.svg",
      text: "Discover how you are innately Wired-Up",
    },
    {
      image: "/assets/Survey-Graphics/wired-up-2.svg",
      text: "Uncover how individual personality types contribute to your team's unique dynamics",
    },
    {
      image: "/assets/Survey-Graphics/wired-up-3.svg",
      text: "Gain insight to understand and Click-With your team",
    },
  ];

  const typography = createTypography();
  return (
    <Stack
    spacing={5}
    justifyContent="center"
    direction={{lg:'row',md:'column', sm:'column', xs:'column'}}
    >
 {cardComponents.map((item) => (
      <SldSurveyCard.SurveyCardStyle
        key={item.text}
        sx={{ width: {xs:'220px',sm:'350px',lg:'30%'} }}
        >
          {item.image ? (
            <SldSurveyCard.SurveyCardMedia
                          >
              <img
                src={item.image}  
                alt={item.text}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </SldSurveyCard.SurveyCardMedia>
          ) : (
            <SldSurveyCard.SurveyCardMedia style={{ height: 238 }} />
          )}

          <SldSurveyCard.SurveyCardContent
        
          >
                    <Typography sx={{ ...typography.cardContent, textAlign: "center" ,wordWrap: 'break-word', fontSize:{xl:'18px',lg:'15px', md:'14px',sm:'14px', xs:'13px'}}}>
              {item.text}
            </Typography>
          </SldSurveyCard.SurveyCardContent>
        </SldSurveyCard.SurveyCardStyle>
      ))}
    </Stack>
  );
}

export default SurveyTile;

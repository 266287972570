import React from "react";
import Typography from "@mui/material/Typography";
import { createTypography } from "src/theme/base/customTypography";
import * as SldSurveyCard from "src/theme/base/SldSurveyCard";
import Stack from "@mui/material/Stack";

/**
 * 063 survey tile components
 * @module 063StartPage - 063SurveyTile
 * @returns {JSX.Element} The rendered component.
 */
function MyLeadershipSurveyTile() {
  const cardComponents = [
    {
      image: "/assets/Survey-Graphics/O63-1.svg",
      text: "Gain a 'mirror' view of your personal leadership brand and style",
    },
    {
      image: "/assets/Survey-Graphics/O63-2.svg",
      text: "Attain insights for your personal and professional development",
    },
    {
      image: "/assets/Survey-Graphics/O63-3.svg",
      text: "Benefit from comprehensive feedback from different angles",
    },
  ];
  const typography = createTypography();
  return (
    <Stack
    spacing={5}
    justifyContent="center"
    direction={{lg:'row',md:'column', sm:'column', xs:'column'}}
    >
  
    {cardComponents.map((item) => (
      <SldSurveyCard.SurveyCardStyle
      key={item.text}
      sx={{ width: {xs:'300px',sm:'350px',lg:'30%'} }}
    >
        {item.image ? (
          <SldSurveyCard.SurveyCardMedia

          >
            <img
                src={item.image}  
                alt={item.text}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
          </SldSurveyCard.SurveyCardMedia>
        ) : (
          <SldSurveyCard.SurveyCardMedia style={{ height: 238 }} />
        )}

        <SldSurveyCard.SurveyCardContent

        >
                    <Typography sx={{ ...typography.cardContent, textAlign: "center" ,wordWrap: 'break-word', fontSize:{xl:'18px',lg:'15px', md:'14px',sm:'14px', xs:'13px'}}}>

            {item.text}
          </Typography>
        </SldSurveyCard.SurveyCardContent>
      </SldSurveyCard.SurveyCardStyle>
    ))}
  </Stack>
  );
}

export default MyLeadershipSurveyTile;

import React from "react";
import Typography from "@mui/material/Typography";
import { createTypography } from "src/theme/base/customTypography";
import * as SldSurveyCard from "src/theme/base/SldSurveyCard";
import Stack from "@mui/material/Stack";

/**
 * Executive health survey tile component
 *@module ExecutiveHealthSurveyStartPage - surveyTile
 * @returns {*} 
 */
function ExecutiveHealthSurveyTile() {
  const cardComponents = [
    {
      image: "/assets/Survey-Graphics/Executive-health-1.svg",
      text: "Assess your overall health and wellness balance",
    },
    {
      image: "/assets/Survey-Graphics/Executive-health-2.svg",
      text: "Gain valuable insights into your well-being to guide you toward a healthier, happier personal and work life",
    },
    {
      image: "/assets/Survey-Graphics/Executive-health-3.svg",
      text: "Improve your leadership ability by investing in your health",
    },
  ];
  const typography = createTypography();
  return (
    <Stack
    spacing={5}
    justifyContent="center"
    direction={{lg:'row',md:'column', sm:'column', xs:'column'}}
    >
 {cardComponents.map((item) => (
      <SldSurveyCard.SurveyCardStyle
        key={item.text}
        sx={{ width: {xs:'300px',sm:'350px',lg:'30%'} }}
      >
      
        {item.image ? (
          <SldSurveyCard.SurveyCardMedia
      
          >
           <img
                src={item.image}  
                alt={item.text}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
          </SldSurveyCard.SurveyCardMedia>
        ) : (
          <SldSurveyCard.SurveyCardMedia style={{ height: 238 }} />
        )}

        <SldSurveyCard.SurveyCardContent
        >
          <Typography sx={{ ...typography.cardContent, textAlign: "center" ,fontSize:{xl:'18px',lg:'15px', md:'14px',sm:'14px', xs:'13px'}}}>
            {item.text}
          </Typography>
        </SldSurveyCard.SurveyCardContent>
      </SldSurveyCard.SurveyCardStyle>
    ))}
  </Stack>
  );
}

export default ExecutiveHealthSurveyTile;

/**
 * @module LeadSelfSettings - removeMember
 */

/**
 * @Author: Anandp
 * @Date:   2024-12-10 15:13:21
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 21:38:08
 */
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ReactComponent as Delete } from "src/icons/untitled-ui/deleteWhite.svg";
import { createTypography } from "src/theme/base/customTypography";
import * as SldButtons from "src/theme/base/SldButtons";
import { useState } from "react";
import axios from "axios";
// import toast from "react-hot-toast";
import { toast } from 'react-toastify';
import { Url } from "src/config";

export const RemoveMemberPopUp = ({
  onClose,
  open = false,
  selectedRows,
  categoryId,
  category_name,
  setSelectedRows,
  setSelectAll,
  ...other
}) => {
  const typography = createTypography();
  // eslint-disable-next-line
  const [error, setError] = useState(null);

  const handleClose = () => {
    onClose();
    setError(null); // Clear any previous errors when closing
  };

  /**
 * Handles the removal of selected users from a team by sending a request to the backend.
 * This function constructs the payload based on the selected rows (users) and sends the data to an API endpoint for processing.
 * Upon success, it clears the selected users and closes the dialog. If an error occurs, it displays an error message.
 *
 * @function handleDelete
 * @returns {void} - No return value. The function sends a POST request to remove selected users and handles the response.
 *
 * @example
 * Example usage: Triggering the delete process for selected rows
 * handleDelete();
 */

  const handleDelete = () => {
    const payload = {
      type: "remove_user",
      jsonData: selectedRows.map((user) => ({ userId: user.id })), // Ensure correct mapping
      category_id: categoryId,
      category_name,
    };

    axios
      .post(`${Url}/customer/removeuserfromteam`, payload, {
        headers: {
          "access-token": sessionStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.success) {
          setError(null); // Clear any previous errors upon success
          toast.success("User remove request sent successfully!");
          setSelectedRows([]);
          setSelectAll(false);
          onClose(); // Close the dialog on success
        } else {
          throw new Error(response.data.message || "Failed to remove users");
        }
      })
      .catch((error) => {
        console.error("Error removing users:", error);
        const errorMessage = error.response?.data?.message || error.message;
        setError("Failed to remove users. Please try again later.");
        toast.error(errorMessage);
      });
  };

  const handleCancel = () => {
    onClose();
    setError(null); // Clear any previous errors when canceling
  };

  return (
    <Dialog open={open}
onClose={handleClose}>
      <DialogTitle justifyContent="space-between">
        <Typography
          align="center"
          sx={typography.contents.huge}
          style={{ fontWeight: "600" }}
        >
          Remove Team Members
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={typography.subtitle.regular}>
          These members will be removed from the team by the admin. If the
          member is in any other teams, they will not be removed from the other
          teams. Please click below to confirm you would like to remove the
          selected members from this team.
        </Typography>
        <Stack
          alignItems="center"
          justifyContent="flex-end"
          direction="row"
          spacing={2}
          pt={4}
        >
          <Stack spacing={2}
direction="row"
alignItems="center">
            <SldButtons.BlackButtonO onClick={handleCancel}>
              <Typography>Cancel</Typography>
            </SldButtons.BlackButtonO>
            <SldButtons.PurpleButton onClick={handleDelete}>
              <Stack spacing={1}
direction="row"
alignItems="center">
                <Delete />
                <Typography>Confirm</Typography>
              </Stack>
            </SldButtons.PurpleButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

RemoveMemberPopUp.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  userIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  categoryId: PropTypes.number.isRequired,
  category_name: PropTypes.string.isRequired,
};
